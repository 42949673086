import React, { useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const Home = () => {
  const form = useRef();

  const [formData, setFormData] = useState({ userName: "", password: "" });
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  function handleSubmit(e) {
    setIsLoading(true);
    e.preventDefault();
    setFormData({ userName: "", password: "" });
    // setSuccess(true);
    // setFormData(prevState => ({ ...prevState, userName: "", password: "" }));
    /*  const requestData = {
      email: formData.userName,
      password: formData.password,
    }; */
    const email = formData.userName;
    const password = formData.password;
    axios
      .post(
        "https://hicukurambar.osideveloper.com/admin/signin",

        {
          email: email,
          password: password,
        },
        {
          auth: {
            username: "holidayosiholiday",
            password: "osiholiday2023",
          },
        }
      )
      .then(async (res) => {
        if (res.status === 200) {
          const token = res.data.token;
          localStorage.setItem("token", token);
          toast.success("Giriş başarılı!");
          await new Promise((resolve) => setTimeout(resolve, 2000));
          setSuccess(true);
        } else {
          setSuccess(false);
        }
      })
      .catch(async (err) => {
        toast.error("Giriş başarısız!");
        console.log(err);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        window.location.reload(true);
      });
    /*  if (formData.userName === "admin" && formData.password === "123123") {
      setSuccess(true);
    } else {
      setSuccess(false);
      alert("INVALID LOGIN INFORMATION");
    } */
  }

  /*  function logIn(e) {
    e.preventDefault();

      .then(
      (result) => {
        toast.success("Message is sent successfully!");
        e.target.reset()
      },
      (error) => {
        toast.error("Message is not sent!");
      }
    );
  } */

  return (
    <>
      {success ? (
        <Navigate to="/admin" />
      ) : (
        <section className="align d-flex justify-content-center align-items-center col-12">
          <ToastContainer />
          <img
            className="screen"
            src="/assets/Images/DSCF0191.jpg"
            alt="background"
          />
          <div className="d-flex justify-content-center align-items-center mt-5 mb-5 col-10 col-md-8 col-lg-6 col-xl-4 bg-dark formstyle">
            <form
              className="col-lg-8 col-10"
              onSubmit={handleSubmit}
              ref={form}
            >
              <fieldset className="fieldset formstyle border col-12 pb-5 pt-4 mb-5">
                <legend className="legend">Giriş Yap</legend>

                <label className="pl-1" For="userName">
                  Email
                </label>
                <input
                  type="text"
                  id="userName"
                  name="userName"
                  className="mb-2"
                  placeholder="Email..."
                  value={formData.userName}
                  onChange={handleChange}
                  required
                  autoComplete="off"
                />

                <label className="pl-1" For="Password">
                  Şifre
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  className="mb-2"
                  placeholder="Şifre..."
                  value={formData.password}
                  onChange={handleChange}
                  required
                />

                <button
                  className="submit p-2 mt-2 bg-success formstyle"
                  type="submit"
                  value="Submit"
                  disabled={isLoading}
                >
                  {isLoading ? "Giriş yapılıyor" : "Giriş Yap"}
                </button>
              </fieldset>
            </form>
          </div>
        </section>
      )}
    </>
  );
};
export default Home;
