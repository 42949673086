import TopBar from "../../InEveryPage/TopBar";
import { minibardata } from "../../Api";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { BsFillTrash3Fill, BsPencilSquare } from "react-icons/bs";
import { Box, CircularProgress } from "@material-ui/core";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";

const MiniBarMenu = () => {
  const [list, setList] = useState([]);

  useEffect(() => {
    const fetchdata = async () => {
      const response = await minibardata();
      setList(
        response.minibarmenu.map((item) => {
          item.url = "minibarmenu";
          return item;
        })
      );
    };
    fetchdata().catch((err) => console.log(err));
  }, []);

  const onClickHandler = (id) => {
    const sendId = id;
    axios
      .post(
        `https://hicukurambar.osideveloper.com/menu/delete/minibarmenu`,
        { id: sendId },
        {
          auth: {
            username: "holidayosiholiday",
            password: "osiholiday2023",
          },
        }
      )
      .then(async (res) => {
        if (res.status === 200) {
          toast.success("Ürün başarıyla silindi");
          await new Promise((resolve) => setTimeout(resolve, 1000));
          window.location.reload(true);
        }
      })
      .catch(async (err) => {
        toast.error("Ürün silinirken bir hata oluştu");
        console.log(err);
      });
  };
/*   let data;
  const resultlist = list.map((key) => {
    return [key].map((data) => {
      return data;
    });
  });
  data = resultlist.flat();
  console.log(data); */
  return (
    <>
      <TopBar />

      <section>
        <ToastContainer />
        {/* <Link to="/create" state={{ data:"minibarmenu" }}>
          <button className="btn btn-success text-end w-100 ">
            <MdOutlineLibraryAdd />
          </button>
        </Link> */}
        <table class="table table-hover">
          <thead class="thead-dark tablehead">
            <tr>
              <th className="col-">İsim</th>
              <th className="col-">Açıklama</th>
              <th className="col-">Fiyat</th>
              <th className="col-">Adet</th>
              <th className="col-">Düzenle</th>
              <th className="col-">Sil</th>
            </tr>
          </thead>
          <tbody>
            {/* <Link to='/edit' state={{ data}} >
                              <button className="btn btn-primary">
                                <BsPencilSquare />asdf
                              </button>
                            </Link> */}
            {list.length > 0 ? (
              list.map((key) => {
                return (
                  <>
                    {[key].map((data) => {
                      return (
                        <>
                          <tr>
                            {data.name ? (
                              <td className="col-2">{data.name}</td>
                            ) : (
                              <td>---</td>
                            )}
                            {data.description ? (
                              <td className="col-4">{data.description}</td>
                            ) : (
                              <td className="col-4">---</td>
                            )}
                            {data.price ? (
                              <td className="col-2">{data.price} tl</td>
                            ) : (
                              <td className="col-2">---</td>
                            )}
                            {data.quantity ? (
                              <td className="col-1">{data.quantity}</td>
                            ) : (
                              <td className="col-1">---</td>
                            )}
                            <td className="col-1">
                              <Link to="/edit" state={{ data }}>
                                <button className="btn btn-primary bg-primary">
                                  <BsPencilSquare />
                                </button>
                              </Link>
                              {/*  <Link to={`edit/${data.id}`}>
                              <button className="btn btn-primary">
                                <BsPencilSquare />
                              </button>
                            </Link> */}
                            </td>
                            <td className="col-1">
                              <button
                                className="btn btn-danger bg-danger"
                                onClick={() => onClickHandler(data.id)}
                              >
                                <BsFillTrash3Fill />
                              </button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </>
                );
              })
            ) : (
              <Box className="position-absolute d-flex justify-content-center align-items-center w-100 h-100">
                <CircularProgress />
              </Box>
            )}
          </tbody>
        </table>
        <div className="d-flex justify-content-start mb-5">
                <Link
                  className="col-2 d-flex justify-content-center align-items-center"
                  to="/create"
                  state={{ data: "minibarmenu" }}
                >
                  <button className="newtbtn w-75 btn btn-success d-flex justify-content-center align-items-center">
                    <MdOutlineLibraryAdd /> Yeni Ürün
                  </button>
                </Link>
              </div>
      </section>
    </>
  );
};

export default MiniBarMenu;
