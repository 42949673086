import PizzaMakarna from "./PizzaMakarna";
import Baslangiclar from "./Baslangiclar";
import BurgerDurum from "./BurgerDurum";
import Icecekler from "./Icecekler";
import Tatlilar from "./Tatlilar";
import Kokteyller from "./Kokteyller";
import Alkoller from "./Alkoller";
import AnaYemek from "./AnaYemek";
import YemeginYaninda from "./YemeginYaninda";
import TopBar from "../../InEveryPage/TopBar";
import { BiUpArrowAlt } from "react-icons/bi";
// import { useEffect, useState } from "react";
// import { useState } from "react";

const Nook = () => {
  // const [content, setContent] = useState(<AnaYemek />)

  /*   const handleClick = (e) => {
      const value = (e.target.value)
      setContent(value)
      console.log(e.target.value)
    } */
  /*  const [scrollPosition, setScrollPosition] = useState(0);
   const handleScroll = () => {
     const position = window.pageYOffset;
     setScrollPosition(position);
   };
   
   useEffect(() => {
     window.addEventListener('scroll', handleScroll, { passive: true });
   
     return () => {
       window.removeEventListener('scroll', handleScroll);
     };
   }, []);
   
   useEffect(() => {
     setTimeout(() => {
       window.scrollTo({ top: scrollPosition, behavior: "instant" });
     }, 0);
   }, [scrollPosition]); */
  /* BROKEN İT WİLL FİXED - fetching speed problem  */
  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "instant" });
    }, 0);
  }
  return (
    <>
      <TopBar />
      <button onClick={scrollToTop} className="totop btn btn-dark bg-dark"><BiUpArrowAlt /></button>

      {/* <nav id="/" className="section">
        <div className="row justify-content-center align-items-center h-75">

          <div className="d-flex w-100 justify-content-around">
            <a className="btn btn-success col-3" href="#anayemek"><button >Ana Yemekler</button></a>
            <a className="btn btn-success col-3" href="#pizzamakarna"><button > Pizzalar & Makarnalar</button></a>
            <a className="btn btn-success col-3" href="#baslangiclar"><button >Başlangıçlar</button></a>
          </div>

          <div className="d-flex w-100 justify-content-around">
            <a className="btn btn-success col-3" href="#burgerdurum"><button >Burgerler & Dürümler</button></a>
            <a  className="btn btn-success col-3" href="#yemeginyaninda"><button>Yemeğin Yanında</button></a>
            <a  className="btn btn-success col-3" href="#tatlilar"><button>Tatlılar</button></a>
          </div>

          <div className="d-flex w-100 justify-content-around">
            <a className="btn btn-success col-3" href="#icecekler"><button >İçecekler</button></a>
            <a className="btn btn-success col-3" href="#kokteyller"><button >Kokteyller</button></a>
            <a  className="btn btn-success col-3" href="#alkoller"><button>Alkoller</button></a>
          </div>
        </div>
      </nav> */}
      <main>
        <div /* id="anayemek" */><AnaYemek /></div>
        <div /* id="pizzamakarna" */><PizzaMakarna /></div>
        <div /* id="baslangiclar" */><Baslangiclar /></div>
        <div /* id="burgerdurum" */><BurgerDurum /></div>
        <div /* id="yemeginyaninda" */><YemeginYaninda /></div>
        <div /* id="tatlilar" */><Tatlilar /></div>
        <div /* id="icecekler" */><Icecekler /></div>
        <div /* id="kokteyller" */><Kokteyller /></div>
        <div /* id="alkoller" */><Alkoller /></div>
      </main>
    </>
  );
};

export default Nook;
