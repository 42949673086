import axios from "axios";

/* Minibarmenu */
export const minibardata = async () => {
  try {
    const res = await axios.get("https://hicukurambar.osideveloper.com/menu/get/minibarmenu", {
      auth: {
        username: "holidayosiholiday",
        password: "osiholiday2023",
      },
    });
    return res.data;
  } catch (err) {
    return err;
  }
};
/* Minibarmenu */

/* NookMenu */
export const anayemek = async () => {
  try {
    const res = await axios.get("https://hicukurambar.osideveloper.com/menu/get/anayemek", {
      auth: {
        username: "holidayosiholiday",
        password: "osiholiday2023",
      },
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const pizzamakarna = async () => {
  try {
    const res = await axios.get("https://hicukurambar.osideveloper.com/menu/get/pizzamakarna", {
      auth: {
        username: "holidayosiholiday",
        password: "osiholiday2023",
      },
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const baslangiclar = async () => {
  try {
    const res = await axios.get("https://hicukurambar.osideveloper.com/menu/get/baslangiclar", {
      auth: {
        username: "holidayosiholiday",
        password: "osiholiday2023",
      },
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const burgerdurum = async () => {
  try {
    const res = await axios.get("https://hicukurambar.osideveloper.com/menu/get/burgerdurum", {
      auth: {
        username: "holidayosiholiday",
        password: "osiholiday2023",
      },
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const icecekler = async () => {
  try {
    const res = await axios.get("https://hicukurambar.osideveloper.com/menu/get/icecekler", {
      auth: {
        username: "holidayosiholiday",
        password: "osiholiday2023",
      },
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const tatlilar = async () => {
  try {
    const res = await axios.get("https://hicukurambar.osideveloper.com/menu/get/tatlilar", {
      auth: {
        username: "holidayosiholiday",
        password: "osiholiday2023",
      },
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const yemeginyaninda = async () => {
  try {
    const res = await axios.get(
      "https://hicukurambar.osideveloper.com/menu/get/yemeginyaninda",
      {
        auth: {
          username: "holidayosiholiday",
          password: "osiholiday2023",
        },
      }
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const kokteyller = async () => {
  try {
    const res = await axios.get("https://hicukurambar.osideveloper.com/menu/get/kokteyller", {
      auth: {
        username: "holidayosiholiday",
        password: "osiholiday2023",
      },
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const alkoller = async () => {
  try {
    const res = await axios.get(
      "https://hicukurambar.osideveloper.com/menu/get/alkoller",
      {
        auth: {
          username: "holidayosiholiday",
          password: "osiholiday2023",
        },
      }
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

/* NookMenu */

/* EngizMenu */
export const engizmenu = async () => {
  try {
    const res = await axios.get("https://hicukurambar.osideveloper.com/menu/get/engizmenu", {
      
      auth: {
        username: "holidayosiholiday",
        password: "osiholiday2023",
      },
    });
    console.log(res)
    return res.data;
  } catch (err) {
    return err;
  }
};
/* EngizMenu */