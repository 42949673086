import axios from "axios";
import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoutes = () => {
  const [loading, setLoading] = useState(true);
  const [control, setControl] = useState(false);
  const storedToken = localStorage.getItem("token");
  useEffect(() => {
    if (storedToken) {
      axios
        .post(
          "https://hicukurambar.osideveloper.com/admin/checkToken",
          { token: storedToken },
          {
            auth: {
              username: "holidayosiholiday",
              password: "osiholiday2023",
            },
          }
        )
        .then((res) => {
          setControl(true);
          setLoading(false);
        })
        .catch((err) => {
          setControl(false);
          setLoading(false);
        });
    } else {
      setControl(false);
      setLoading(false);
    }
  }, [storedToken]);
  if (loading) {
  } else {
    if (control) {
      return <Outlet />;
    } else if (!control) {
      return <Navigate to="/" />;
    }
  }
};

export default ProtectedRoutes;

// import axios from "axios";
// import React, { Component, Fragment } from "react";
// import { Navigate, Outlet } from "react-router-dom";
// import { ToastContainer, toast } from "react-toastify";

// export default function withAuth() {
//   return class extends Component {
//     constructor() {
//       super();
//       this.state = {
//         loading: true,
//         redirect: false,
//       };
//     }
//     componentDidMount() {

//       if (
//         localStorage.token === "undefined" ||
//         localStorage.token === "" ||
//         localStorage.getItem("token") === null
//       ) {
//         this.setState({ loading: false, redirect: true });
//       } else {
//         try {
//           const token =  localStorage.getItem("token");

//                 axios
//         .post(
//           "https://hicukurambar.osideveloper.com/admin/checkToken",
//           { token: token },
//           {
//             auth: {
//               username: "holidayosiholiday",
//               password: "osiholiday2023",
//             },
//           }
//         )
//               .then((response) => {
//                     this.setState({ loading: false });

//               })
//               .catch((err) => {

//                 if (err.request.response == "") {
//                    this.setState({ loading: false, redirect: true });
//                 } else {
//                    this.setState({ loading: false, redirect: true });
//                 }
//               });

//         } catch (err) {

//            this.setState({ loading: false, redirect: true });
//         }
//       }
//     }
//     render() {
//       const { loading, redirect } = this.state;
//       if (loading) {
//           return null;
//       }
//       if (redirect) {
//           return  <Navigate to="/" />
//       }
//       return (
//           <Fragment>
//                     <ToastContainer />
//                    <Outlet/>

//           </Fragment>

//       );
//     }
//   };
// }
