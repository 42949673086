import React, { useEffect, useState } from "react";
import { engizmenu } from "../../Api";
import axios from "axios";
import { Box, CircularProgress } from "@material-ui/core";
import { BsFillTrash3Fill, BsPencilSquare } from "react-icons/bs";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { MdOutlineLibraryAdd } from "react-icons/md";

const EngizMenu = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    /* Getting data from Api.js then given category to response */
    const fetchdata = async () => {
      const response = await engizmenu();
      setList(
        /* Object.keys(response).flatMap((key) => {
          return response[key].map((item) => {
            item.url = "engizmenu";
            item.category = key;
            return [item];
          });
        }) */ response
      );
    };
    fetchdata().catch((err) => console.log(err));
  }, []);

  const onClickHandler = (id, category) => {
    setIsLoading(true);
    const requestData = {
      id: id,
      category: category,
    };
    axios
      .post(
        `https://hicukurambar.osideveloper.com/menu/delete/engizmenu`,
        requestData,
        {
          auth: {
            username: "holidayosiholiday",
            password: "osiholiday2023",
          },
        }
      )
      .then(async (res) => {
        if (res.status === 200) {
          toast.success("Ürün Başarıyla silindi!");
          await new Promise((resolve) => setTimeout(resolve, 1000));
          window.location.reload(true);
        }
      })
      .catch(async (err) => {
        toast.error("Ürün silinirken bir hata oluştu!");
        console.log(err);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        window.location.reload(true);
      });
  };
  return (
    <section>
      {list ? (
        Object.keys(list).map((key) => {
          return (
            <>
              <h4 className="tabletitle w-100 p-3 pl-5 text-start">{key}</h4>
              <ToastContainer />

              <table class="table table-hover">
                <thead class="thead-dark tablehead">
                  <tr>
                    <th className="col-">İsim</th>
                    <th className="col-">Açıklama</th>
                    <th className="col-">Fiyat</th>
                    <th className="col-">Adet</th>
                    <th className="col-">Düzenle</th>
                    <th className="col-">Sil</th>
                  </tr>
                </thead>
                <tbody>
                  {list[key].map((data) => {
                    data.url = "engizmenu";
                    data.category = key;
                    return (
                      <>
                        <tr>
                          {data.name ? (
                            <td className="col-2">{data.name}</td>
                          ) : (
                            <td>---</td>
                          )}
                          {data.description ? (
                            <td className="col-4">{data.description}</td>
                          ) : (
                            <td className="col-4">---</td>
                          )}
                          {data.price ? (
                            <td className="col-2">{data.price} ₺</td>
                          ) : (
                            <td className="col-2">---</td>
                          )}
                          {data.quantity ? (
                            <td className="col-1">{data.quantity}</td>
                          ) : (
                            <td className="col-1">---</td>
                          )}
                          <td className="col-1">
                            <Link to="/edit" state={{ data }}>
                              {/* sending data with Link state */}
                              <button className="btn btn-primary bg-primary">
                                <BsPencilSquare />
                              </button>
                            </Link>
                          </td>
                          <td className="col-1">
                            <button
                              className="btn btn-danger bg-danger"
                              onClick={() =>
                                onClickHandler(data.id, data.category)
                              }
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <CircularProgress className="loading" />
                              ) : (
                                <BsFillTrash3Fill />
                              )}
                            </button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
              <div className="d-flex justify-content-start mb-5">
                <Link
                  className="col-2 d-flex justify-content-center align-items-center"
                  to="/create"
                  state={{ data: "engizmenu", key }}
                >
                  <button className="newtbtn w-75 btn btn-success d-flex justify-content-center align-items-center">
                    <MdOutlineLibraryAdd /> Yeni Ürün
                  </button>
                </Link>
              </div>
            </>
          );
        })
      ) : (
        <>
          <Box className="position-absolute d-flex justify-content-center align-items-center w-100 h-100">
            <CircularProgress />
          </Box>
          <br />
          <span>Ürün Bulunamadı.</span>
        </>
      )}
    </section>
  );
};

export default EngizMenu;
