import React, { useEffect, useState } from "react";
import { alkoller } from "../../Api";
import axios from "axios";
import { Box, CircularProgress } from "@material-ui/core";
import { BsFillTrash3Fill, BsPencilSquare } from "react-icons/bs";
import { Link } from "react-router-dom";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";

const Alkoller = () => {
  const [list, setList] = useState([]);

  useEffect(() => {
    /* Getting data from Api.js then given category to response */
    const fetchdata = async () => {
      const response = await alkoller();
      setList(
        /*   Object.keys(response).map((key) =>
          Array.isArray(response)
            ? response[key].map((item) => item)
            : Object.keys(response[key]).map((item) =>
                response[key][item].map((data) => data)
              )
        ) */
        /* Object.keys(response).map((key) => (
          Object.keys(response[key]).map((item) => (
            response[key][item].length > 0 ? response[key][item].map((data)=> {
              return data
            }): (item)
          ))
        )) */
        response
      );
    };
    fetchdata().catch((err) => console.log(err));
  }, []);

  const onClickHandler = (id, category, subcategory) => {
    /* const sendId = id;
    const sendCategory = category;
    const sendSubcategory = subcategory; */
    const requestData = {
      id: id,
      category: category,
      subcategory: subcategory,
    };
    axios
      .post(
        `https://hicukurambar.osideveloper.com/menu/delete/alkoller`,
        /* { id: sendId },
        { category: sendCategory },
        { subcategory: sendSubcategory }, */
        requestData,
        {
          auth: {
            username: "holidayosiholiday",
            password: "osiholiday2023",
          },
        }
      )
      .then(async (res) => {
        if (res.status === 200) {
          toast.success("Ürün başarıyla silindi");
          await new Promise((resolve) => setTimeout(resolve, 1000));
          window.location.reload(true);
        }
      })
      .catch(async (err) => {
        toast.error("Ürün silinirken bir hata oluştu");
        console.log(err);
      });
  };
  return (
    <section>
      <h4 className="w-100 text-start p-3 pl-5 tabletitle">Alkoller</h4>
      <ToastContainer />
      {/* <Link to="/create" state={{ data: "alkoller" }}>
        <button className="btn btn-success text-end w-100 ">
          <MdOutlineLibraryAdd />
        </button>
      </Link> */}
      <table class="table table-hover">
        <thead class="thead-dark tablehead">
          <tr>
            <th className="col-">İsim</th>
            <th className="col-">Açıklama</th>
            <th className="col-">Fiyat</th>
            <th className="col-">Adet</th>
            <th className="col-">Düzenle</th>
            <th className="col-">Sil</th>
          </tr>
        </thead>
        <tbody>
          {list ? (
            Object.keys(list).map((key) => {
              return Object.keys(list[key]).map((key2) => {
                return list[key][key2].map((data) => {
                  data.url = "alkoller";
                  data.category = key;
                  data.subcategory = key2;
                  return (
                    <>
                      {/* <h6>{key}</h6> */}
                      <tr>
                        {data.name ? (
                          <td className="col-2">{data.name}</td>
                        ) : (
                          <td>---</td>
                        )}
                        {data.description ? (
                          <td className="col-4">{data.description}</td>
                        ) : (
                          <td className="col-4">---</td>
                        )}
                        {data.price ? (
                          <td className="col-2">{data.price} ₺</td>
                        ) : (
                          <td className="col-2">---</td>
                        )}
                        {data.quantity ? (
                          <td className="col-1">{data.quantity}</td>
                        ) : (
                          <td className="col-1">---</td>
                        )}
                        <td className="col-1">
                          <Link to="/edit" state={{ data }}>
                            {/* sending data with Link state */}
                            <button className="btn btn-primary bg-primary">
                              <BsPencilSquare />
                            </button>
                          </Link>
                        </td>
                        <td className="col-1">
                          <button
                            className="btn btn-danger bg-danger"
                            onClick={() =>
                              onClickHandler(
                                data.id,
                                data.category,
                                data.subcategory
                              )
                            }
                          >
                            <BsFillTrash3Fill />
                          </button>
                        </td>
                      </tr>
                    </>
                  );
                });
              });
            })
          ) : (
            <Box className="position-absolute d-flex justify-content-center align-items-center w-100 h-100">
              <CircularProgress />
            </Box>
          )}
        </tbody>
      </table>
      <div className="d-flex justify-content-start mb-5">
        <Link
          className="col-2 d-flex justify-content-center align-items-center"
          to="/create"
          state={{ data: "alkoller" }}
        >
          <button className="newtbtn w-75 btn btn-success d-flex justify-content-center align-items-center">
            <MdOutlineLibraryAdd /> Yeni Ürün
          </button>
        </Link>
      </div>
    </section>
  );
};

export default Alkoller;
