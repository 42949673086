import { BiUpArrowAlt } from "react-icons/bi";
import TopBar from "../../InEveryPage/TopBar";
import EngizMenu from "./EngizMenu.js"



const EngizMenuPage = () => {
  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "instant" });
    }, 0);
  }
  return (
    <>
      <TopBar />
      <button onClick={scrollToTop} className="totop btn btn-dark bg-dark"><BiUpArrowAlt /></button>
      <main >
        <EngizMenu />
      </main>
    </>
  );
};

export default EngizMenuPage;
