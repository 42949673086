import axios from "axios";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TopBar from "../InEveryPage/TopBar";
import { ToastContainer, toast } from "react-toastify";
// import { useLocation, useParams } from "react-router-dom";
// import axios from "axios";

const Edit = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { data } = location.state;

  const [updateData, setUpdateData] = useState(data);

  /* İf you give to useState an empty content 
  it will send empty input value, to solve that 
  use useEffect like the example bellow 
  {
    name: "",
    description: "",
    price: "",
    quantity: "",
  }

  ----- optional -----
  
  useEffect(() => {
    setUpdateData(data)
  }, [data])
 */
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    // Set loading state to true
    setIsLoading(true);
    e.preventDefault();
    const requestData = {
      id: data.id,
      name: updateData.name,
      description: updateData.description,
      price: updateData.price,
      quantity: updateData.quantity,
      category: data.category,
      subcategory: data.subcategory,
    };

    // Perform the actual request here
    axios
      .post(
        `https://hicukurambar.osideveloper.com/menu/update/${data.url}`,
        requestData,
        {
          auth: {
            username: "holidayosiholiday",
            password: "osiholiday2023",
          },
        }
      )
      .then(async (res) => {
        if (res.status === 200) {
          toast.success("Ürün başarıyla düzenlendi!");
          await new Promise((resolve) => setTimeout(resolve, 3000));
          navigate(-1);
        }
      })
      .catch(async (err) => {
        toast.error("Ürün düzenlenirken bir hata oluştu!");
        await new Promise((resolve) => setTimeout(resolve, 3000));
        navigate(-1);
        console.log(err);
      });
    // After the request is completed, set loading state to false
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  /*   const handleSubmit = async (e) => {
    e.preventDefault();
    const requestData = {
      id: data.id,
      name: updateData.name,
      description: updateData.description,
      price: updateData.price,
      quantity: updateData.quantity,
      category: data.category,
      subcategory: data.subcategory,
    };
    axios
      .post(
        `https://hicukurambar.osideveloper.com/menu/update/${data.url}`,
        requestData,
        {
          auth: {
            username: "holidayosiholiday",
            password: "osiholiday2023",
          },
        }
      )
      .then(async (res) => {
        if (res.status === 200) {
          toast.success("Ürün başarıyla düzenlendi!");
          await new Promise((resolve) => setTimeout(resolve, 3000));
          navigate(-1);
        }
      })
      .catch(async (err) => {
        toast.error("Ürün düzenlenirken bir hata oluştu!");
        await new Promise((resolve) => setTimeout(resolve, 3000));
        navigate(-1);
        console.log(err);
      });
  }; WITHOUT CHECKING THE BUTTON IF REQUEST SENTS */

  return (
    <>
      <TopBar />
      <section className="align d-flex justify-content-center align-items-center col-12">
        <ToastContainer />
        <div className="d-flex justify-content-center align-items-center mt-5 mb-5 col-10 col-md-8 col-lg-6 col-xl-6 bg-dark formstyle">
          <form
            className="col-lg-8 col-10"
            onSubmit={handleSubmit}
            // ref={form}
          >
            <fieldset className="fieldset formstyle border col-12 pb-5 pt-4 mb-5">
              <legend className="legend">Ürün Düzenleme</legend>

              <label className="pl-1 labelHead text-light" For="name">
                İsim
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="inputClass"
                placeholder="İsim..."
                defaultValue={data.name}
                onChange={handleChange}
                required
                autoComplete="off"
              />
               <label className="pl-1 p-0 mt-0 mb-2" For="name">
                Ör: Çorba / Soup
              </label>

              <label className="pl-1 labelHead text-light" For="description">
                Açıklama
              </label>
              <input
                type="description"
                id="description"
                name="description"
                className="inputClass"
                placeholder="Açıklama..."
                defaultValue={data.description}
                onChange={handleChange}
              />
               <label className="pl-1 p-0 mt-0 mb-2" For="description">
                Ör: Servis şekli, içindekiler vs.
              </label>

              <label className="pl-1 labelHead text-light" For="price">
                Fiyat
              </label>
              <input
                type="price"
                id="price"
                name="price"
                className="inputClass"
                placeholder="Fiyat..."
                defaultValue={data.price}
                onChange={handleChange}
                required
              />
               <label className="pl-1 p-0 mt-0 mb-2" For="price">
                Ör: 1.000
              </label>

              <label className="pl-1 labelHead text-light" For="quantity">
                Adet
              </label>
              <input
                type="quantity"
                id="quantity"
                name="quantity"
                className="inputClass"
                placeholder="Adet, Kalori"
                defaultValue={data.quantity}
                onChange={handleChange}
              />
               <label className="pl-1 p-0 mt-0 mb-2" For="quantity">
                Ör: Adet:1, Kalori:250.
              </label>

              <button
                className="submit p-2 mt-2 bg-success formstyle"
                type="submit"
                value="Submit"
                disabled={isLoading}
              >
                {isLoading ? "Düzenleniyor..." : "Düzenle"}
              </button>
            </fieldset>
          </form>
        </div>
      </section>
    </>
  );
};

export default Edit;
