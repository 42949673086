import React from "react";
import { /* FaArrowLeft, FaArrowRight, */ FaHome } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { Link/* , useNavigate */ } from "react-router-dom";

const TopBar = () => {
  // const navigate = useNavigate();
  const handleClick = () => {
    localStorage.removeItem("token");
    window.location.reload(true);
  };

  return (
    <section className="z d-flex justify-content-center pt-0 pb-5">
      <div className="topbar col-12 position-fixed d-flex justify-content-end align-items-center bg-dark p-3">
        {/* <div>
          <button onClick={() => navigate(-1)} className="mr-3 ml-3">
            <FaArrowLeft />
          </button>
          <button onClick={() => navigate(1)} className="mr-3 ml-3">
            <FaArrowRight />
          </button>
        </div> */}
        <div>
          <button className="mr-3 ml-3">
            <Link to="/admin">
              <h4>
                <FaHome />
              </h4>
            </Link>
          </button>
          <button onClick={handleClick} className="mr-3 ml-3">
            <h4>
              <MdLogout />
            </h4>
          </button>
        </div>
      </div>
    </section>
  );
};

export default TopBar;
