import "./App.css";
import "./CSS/Home.css";
import "./CSS/Admin.css";
import "./CSS/Minibarmenu.css";
import "./CSS/NookMenu.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Admin from "./Pages/Admin";
import Edit from "./Pages/Edit";
import EngizMenuPage from "./Pages/Engiz/EngizMenuPage.jsx";
import NookMenu from "./Pages/Nook/NookMenu";
import MiniBarMenu from "./Pages/Minibar/MiniBarMenu";
import ProtectedRoutes from "./Pages/ProtectedRoutes";
import Create from "./Pages/Create";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/home" element={<Home />} />

          <Route element={<ProtectedRoutes />}>
            <Route exact path="/admin" element={<Admin />} />
            <Route exact path="/edit" element={<Edit />} />
            <Route exact path="/create" element={<Create />} />
            <Route exact path="/engizmenu" element={<EngizMenuPage />} />
            <Route exact path="/nookmenu" element={<NookMenu />} />
            <Route exact path="/minibarmenu" element={<MiniBarMenu />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
