import React from "react";
import { Link } from "react-router-dom";
import TopBar from "../InEveryPage/TopBar";

const LogIn = () => {
  return (
    <>
      <TopBar />

      {/* ---- */}

      <section className="container d-flex flex-md-row flex-column justify-content-center align-items-center col-12">
        <Link className=" d-flex justify-content-center" to="/EngizMenu">
          <div className="card bg-dark d-flex justify-content-center m-3 col-6 col-sm-8 col-md-10 col-lg-8 p-0">
            <img
              className="cardImg"
              src="/assets/Images/holidayengiz.jpg"
              alt="background"
            />
            <h4 className="position-absolute text-center w-100">Room Services</h4>
          </div>
        </Link>
        <Link className="d-flex justify-content-center"  to="/NookMenu">
          <div className="card bg-dark d-flex justify-content-center m-3 col-6 col-sm-8 col-md-10 col-lg-8 p-0">
            <img
              className="cardImg"
              src="/assets/Images/holidaynook.jpg"
              alt="background"
            />
            <h4 className="position-absolute text-center w-100">Nook Menu</h4>
          </div>
        </Link>
        <Link className="d-flex justify-content-center" to="/MiniBarMenu">
          <div className="card bg-dark d-flex justify-content-center m-3 col-6 col-sm-8 col-md-10 col-lg-8 p-0">
            <img
              className="cardImg"
              src="/assets/Images/holidayminibar.jpg"
              alt="background"
            />
            <h4 className="position-absolute text-center w-100">Minibar Menu</h4>
          </div>
        </Link>
      </section>
    </>
  );
};

export default LogIn;
