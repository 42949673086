import axios from "axios";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TopBar from "../InEveryPage/TopBar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Create = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  // const selectRef = useRef(null);

  const { data, key } = location.state;

  const [updateData, setUpdateData] = useState({
    name: "",
    description: "",
    price: "",
    quantity: "",
    category: "",
    subcategory: "",
  });
  /*   const [categoryUrl, setCategoryUrl] = useState("");
  const [subCategoryUrl, setSubCategoryUrl] = useState(""); */

  // const categoryUrl = selectRef.current.value;

  /*   const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setCategoryUrl((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(categoryUrl);
  };
  const handleSubSelectChange = (e) => {
    const { name, value } = e.target;
    setSubCategoryUrl((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(categoryUrl);
  };
 */
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const requestData = {
      name: updateData.name,
      description: updateData.description,
      price: updateData.price,
      quantity: updateData.quantity,
      category: updateData.category,
      subcategory: updateData.subcategory,
    };
    axios
      .post(
        `https://hicukurambar.osideveloper.com/menu/add/${data}`,
        requestData,
        {
          auth: {
            username: "holidayosiholiday",
            password: "osiholiday2023",
          },
        }
      )
      .then(async (res) => {
        if (res.status === 200) {
          toast.success("Ürün başarıyla eklendi!");
          await new Promise((resolve) => setTimeout(resolve, 3000));
          navigate(-1);
        }
      })
      .catch(async (err) => {
        toast.error("Ürün eklenirken bir hata oluştu!");
        await new Promise((resolve) => setTimeout(resolve, 3000));
        navigate(-1);
        console.log(err);
      });
  };
  return (
    <>
      <TopBar />
      <section className="align d-flex justify-content-center align-items-center col-12">
        <ToastContainer />
        <div className="d-flex justify-content-center align-items-center mt-5 mb-5 col-10 col-md-8 col-lg-6 col-xl-6 bg-dark formstyle">
          <form
            className="col-lg-8 col-10"
            onSubmit={handleSubmit}
            // ref={form}
          >
            <fieldset className="fieldset formstyle border col-12 pb-5 pt-4 mb-5">
              <legend className="legend">Yeni Ürün</legend>

              <label className="pl-1 text-light labelHead" For="name">
                İsim
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="inputClass"
                placeholder="İsim..."
                defaultValue={data.name}
                onChange={handleChange}
                required
                autoComplete="off"
              />
              <label className="pl-1 p-0 mt-0 mb-2" For="name">
                Ör: Çorba / Soup
              </label>

              <label className="pl-1 text-light labelHead" For="description">
                Açıklama
              </label>
              <input
                type="description"
                id="description"
                name="description"
                className="inputClass"
                placeholder="Açıklama..."
                defaultValue={data.description}
                onChange={handleChange}
              />
              <label className="pl-1 p-0 mt-0 mb-2" For="description">
                Ör: Servis şekli, içindekiler vs.
              </label>

              <label className="pl-1 text-light labelHead" For="price">
                Fiyat
              </label>
              <input
                type="price"
                id="price"
                name="price"
                className="inputClass"
                placeholder="Fiyat..."
                defaultValue={data.price}
                onChange={handleChange}
                required
              />
              <label className="pl-1 p-0 mt-0 mb-2" For="price">
                Ör: 1.000
              </label>

              <label className="pl-1 text-light labelHead" For="quantity">
                Adet, Kalori
              </label>
              <input
                type="quantity"
                id="quantity"
                name="quantity"
                className="inputClass"
                placeholder="Adet, Kalori..."
                defaultValue={data.quantity}
                onChange={handleChange}
              />
              <label className="pl-1 p-0 mt-0 mb-2" For="quantity">
                Ör: Adet:1, Kalori:250
              </label>
              {data === "minibarmenu" ||
              data === "anayemek" ||
              data === "baslangiclar" ||
              data === "burgerdurum" ||
              data === "pizzamakarna" ||
              data === "tatlilar" ||
              data === "yemeginyaninda" ? null : (
                <>
                  <label className="pl-1 text-light labelHead" For="category">
                    Category
                  </label>
                  <select
                    id="category"
                    name="category"
                    className="mb-2 inputClass"
                    placeholder="Category..."
                    Value={updateData}
                    onChange={handleChange}
                    required
                  >
                    <option value="">-- Select -- </option>
                    {/* icecekler */}
                    {data === "icecekler" ? (
                      <>
                        <option value="SMOOTHIES">SMOOTIES</option>
                        <option value="FROZEN">FROZEN</option>
                        <option value="MESRUBATLAR">MESRUBATLAR</option>
                      </>
                    ) : null}
                    {/* iccorbalarecekler */}

                    {/* engizmenu */}
                    {data === "engizmenu" ? (
                      <>
                        {key === "corbalar" ? (
                          <option value="corbalar">corbalar</option>
                        ) : null}
                        {key === "pizzalar" ? (
                          <option value="pizzalar">pizzalar</option>
                        ) : null}

                        {key === "anayemek" ? (
                          <option value="anayemek">anayemek</option>
                        ) : null}
                        {key === "salatalar" ? (
                          <option value="salatalar">salatalar</option>
                        ) : null}
                        {key === "burgerdurum" ? (
                          <option value="burgerdurum">burgerdurum</option>
                        ) : null}
                        {key === "sidedish" ? (
                          <option value="sidedish">sidedish</option>
                        ) : null}
                        {key === "tatli" ? (
                          <option value="tatli">tatli</option>
                        ) : null}
                        {key === "kirmizisaraplar" ? (
                          <option value="kirmizisaraplar">
                            kirmizisaraplar
                          </option>
                        ) : null}
                        {key === "beyazsaraplar" ? (
                          <option value="beyazsaraplar">beyazsaraplar</option>
                        ) : null}
                        {key === "rosesaraplar" ? (
                          <option value="rosesaraplar">rosesaraplar</option>
                        ) : null}
                        {key === "sisekirmizisaraplar" ? (
                          <option value="sisekirmizisaraplar">
                            sisekirmizisaraplar
                          </option>
                        ) : null}
                        {key === "sisebeyazsaraplar" ? (
                          <option value="sisebeyazsaraplar">
                            sisebeyazsaraplar
                          </option>
                        ) : null}
                        {key === "siserosesaraplar" ? (
                          <option value="siserosesaraplar">
                            siserosesaraplar
                          </option>
                        ) : null}
                        {key === "sampanyalar" ? (
                          <option value="sampanyalar">sampanyalar</option>
                        ) : null}
                        {key === "ithalsisekirmizisaraplar" ? (
                          <option value="ithalsisekirmizisaraplar">
                            ithalsisekirmizisaraplar
                          </option>
                        ) : null}
                        {key === "ithalsisebeyazsaraplar" ? (
                          <option value="ithalsisebeyazsaraplar">
                            ithalsisebeyazsaraplar
                          </option>
                        ) : null}
                        {key === "ithalsiserosesaraplar" ? (
                          <option value="ithalsiserosesaraplar">
                            ithalsiserosesaraplar
                          </option>
                        ) : null}
                        {key === "viskiler" ? (
                          <option value="viskiler">viskiler</option>
                        ) : null}
                        {key === "rakilar" ? (
                          <option value="rakilar">rakilar</option>
                        ) : null}
                        {key === "konyaklar" ? (
                          <option value="konyaklar">konyaklar</option>
                        ) : null}
                        {key === "kokteyller" ? (
                          <option value="kokteyller">kokteyller</option>
                        ) : null}
                        {key === "biralar" ? (
                          <option value="biralar">biralar</option>
                        ) : null}
                        {key === "likorler" ? (
                          <option value="likorler">likorler</option>
                        ) : null}
                        {key === "rom" ? (
                          <option value="rom">rom</option>
                        ) : null}
                        {key === "vermut" ? (
                          <option value="vermut">vermut</option>
                        ) : null}
                        {key === "cin" ? (
                          <option value="cin">cin</option>
                        ) : null}
                        {key === "tekila" ? (
                          <option value="tekila">tekila</option>
                        ) : null}
                        {key === "votka" ? (
                          <option value="votka">votka</option>
                        ) : null}
                        {key === "mesrubatlar" ? (
                          <option value="mesrubatlar">mesrubatlar</option>
                        ) : null}
                        {key === "tazeicecekler" ? (
                          <option value="tazeicecekler">tazeicecekler</option>
                        ) : null}
                        {key === "sicakicecekler" ? (
                          <option value="sicakicecekler">sicakicecekler</option>
                        ) : null}
                         {key === "cocukmenu" ? (
                          <option value="cocukmenu">cocukmenu</option>
                        ) : null}
                      </>
                    ) : null}
                    {/* engizmenu */}

                    {/* kokteyller */}
                    {data === "kokteyller" ? (
                      <>
                        <option value="SPECIAL COCTAILS">
                          SPECIAL COCTAILS
                        </option>
                        <option value="GIN TONIC COCTAILS">
                          GIN TONIC COCTAILS
                        </option>
                        <option value="CLASSIC COCTAILS">
                          CLASSIC COCTAILS
                        </option>
                      </>
                    ) : null}
                    {/* kokteyller */}
                    {/* alkoller */}
                    {data === "alkoller" ? (
                      <>
                        <option value="BIRALAR">BIRALAR</option>
                        <option value="SARAPLAR/WINES">SARAPLAR/WINES</option>
                        <option value="VISKILER/WHISKEY">
                          VISKILER/WHISKEY
                        </option>
                        <option value="VOTKA">VOTKA</option>
                        <option value="CIN">CIN</option>
                        <option value="TEKILA">TEKILA</option>
                        <option value="KONYAKLAR">KONYAKLAR</option>
                        <option value="LIKORLER">LIKORLER</option>
                        <option value="ROM">ROM</option>
                        <option value="VERMUT">VERMUT</option>
                      </>
                    ) : null}
                    {/* alkoller */}
                  </select>
                </>
              )}
              {data === "minibarmenu" ||
              data === "anayemek" ||
              data === "baslangiclar" ||
              data === "burgerdurum" ||
              data === "pizzamakarna" ||
              data === "tatlilar" ||
              data === "icecekler" ||
              data === "yemeginyaninda" ||
              data === "engizmenu" ? null : (
                <>
                  <label className="pl-1 text-light labelHead" For="subcategory">
                    Subcategory
                  </label>
                  <select
                    id="subcategory"
                    name="subcategory"
                    className="mb-2 inputClass"
                    placeholder="Subcategory..."
                    Value={updateData}
                    onChange={handleChange}
                    required
                  >
                    <option value="">-- Select -- </option>
                    {/* kokteyller */}

                    {updateData.category === "SPECIAL COCTAILS" ? (
                      <>
                        <option value="APERATIF KOKTEYLLER">
                          "APERATIF KOKTEYLLER"
                        </option>
                        <option value="YEMEK SONRASI KOKTEYLLER">
                          "YEMEK SONRASI KOKTEYLLER"
                        </option>
                        <option value="YUKSEK ALKOLLU KOKTEYLLER">
                          "YUKSEK ALKOLLU KOKTEYLLER"
                        </option>
                      </>
                    ) : null}
                    {updateData.category === "CLASSIC COCTAILS" ? (
                      <option value="CLASSIC COCTAILS">
                        "CLASSIC COCTAILS"
                      </option>
                    ) : null}
                    {updateData.category === "GIN TONIC COCTAILS" ? (
                      <option value="GIN TONIC COCTAILS">
                        "GIN TONIC COCTAILS"
                      </option>
                    ) : null}
                    {/* kokteyller */}

                    {/* alkoller */}
                    {updateData.category === "BIRALAR" ? (
                      <option value="BIRALAR / BEERS">"BIRALAR / BEERS"</option>
                    ) : null}
                    {/* alkoller */}

                    {/* saraplar */}
                    {updateData.category === "SARAPLAR/WINES" ? (
                      <>
                        <option value="KIRMIZI SARAPLAR">
                          "KIRMIZI SARAPLAR"
                        </option>
                        <option value="BEYAZ SARAPLAR/WHITE WINES">
                          "BEYAZ SARAPLAR/WHITE WINES"
                        </option>
                        <option value="ROSE SARAPLAR/ROSE WINES">
                          "ROSE SARAPLAR/ROSE WINES"
                        </option>
                      </>
                    ) : null}
                    {/* saraplar */}

                    {/* viskiler */}
                    {updateData.category === "VISKILER/WHISKEY" ? (
                      <>
                        <option value="AMERICAN VISKISI/AMERIKAN WHISKEY">
                          "AMERICAN VISKISI/AMERIKAN WHISKEY"
                        </option>
                        <option value="ISKOC VISKISI/SCOTCH WHISKEY">
                          "ISKOC VISKISI/SCOTCH WHISKEY"
                        </option>
                        <option value="HİNT VİSKİSİ">"HİNT VİSKİSİ"</option>
                        <option value="SINGLE MALT VISKISI/SINGLE MALT WHISKEY">
                          "SINGLE MALT VISKISI/SINGLE MALT WHISKEY"
                        </option>
                        <option value="IRLANDA VISKISI/IRISH WHISKEY">
                          "IRLANDA VISKISI/IRISH WHISKEY"
                        </option>
                      </>
                    ) : null}
                    {/* viskiler */}

                    {/* votka */}
                    {updateData.category === "VOTKA" ? (
                      <option value="VOTKA/VODKA">"VOTKA/VODKA"</option>
                    ) : null}
                    {/* votka */}

                    {/* cin */}
                    {updateData.category === "CIN" ? (
                      <option value="CIN/GIN">"CIN/GIN"</option>
                    ) : null}
                    {/* cin */}

                    {/* tekila */}
                    {updateData.category === "TEKILA" ? (
                      <option value="TEKILA/TEQUILA">"TEKILA/TEQUILA"</option>
                    ) : null}
                    {/* tekila */}

                    {/* konyaklar */}
                    {updateData.category === "KONYAKLAR" ? (
                      <option value="KONYAKLAR/COGNAC">
                        "KONYAKLAR/COGNAC"
                      </option>
                    ) : null}
                    {/* konyaklar */}

                    {/* likorler */}
                    {updateData.category === "LIKORLER" ? (
                      <option value="LIKORLER/LIQUEUR">
                        "LIKORLER/LIQUEUR"
                      </option>
                    ) : null}
                    {/* likorler */}

                    {/* rom */}
                    {updateData.category === "ROM" ? (
                      <option value="ROM">"ROM"</option>
                    ) : null}
                    {/* rom */}

                    {/* vermut */}
                    {updateData.category === "VERMUT" ? (
                      <option value="VERMUT">"VERMUT"</option>
                    ) : null}
                    {/* vermut */}
                  </select>
                </>
              )}

              <button
                className="submit p-2 mt-2 bg-success formstyle"
                type="submit"
                value="Submit"
                disabled={isLoading}
              >
                {isLoading ? "Oluşturuluyor..." : "Oluştur"}
              </button>
            </fieldset>
          </form>
        </div>
      </section>
    </>
  );
};

export default Create;
